import LoopSlider from "./LoopSlider";
import Swiper from "swiper";
import { Pagination, Autoplay, Navigation, Grid } from "swiper/modules";

let homeBannerSlider = new Swiper(".home-banner-slider .swiper", {
	modules: [Pagination, Autoplay],
	slidesPerView: 1,
	spaceBetween: 12,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	pagination: {
		el: ".home-banner-slider .swiper-pagination",
		clickable: true,
	},
});

let newsFeaturedSlider = new Swiper(".news-featured-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1,
	spaceBetween: 32,
	navigation: {
		nextEl: ".news-featured-slider .swiper-btn-next",
		prevEl: ".news-featured-slider .swiper-btn-prev",
	},
});

const stepSlider = new Swiper(".step-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1.25,
	spaceBetween: 16,
	navigation: {
		nextEl: ".step-slider .swiper-btn-next",
		prevEl: ".step-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 2.25,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 3.25,
			spaceBetween: 16,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 32,
		},
	},
});

const feedbackSlider = new Swiper(".feedback-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1.25,
	spaceBetween: 16,
	navigation: {
		nextEl: ".feedback-slider .swiper-btn-next",
		prevEl: ".feedback-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 1.25,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 2.5,
			spaceBetween: 16,
		},
		1024: {
			slidesPerView: 1.65,
			spaceBetween: 32,
		},
	},
});

const gallerySlider = new Swiper(".gallery-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1.25,
	spaceBetween: 16,
	navigation: {
		nextEl: ".gallery-slider .swiper-btn-next",
		prevEl: ".gallery-slider .swiper-btn-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 1.25,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 2.5,
			spaceBetween: 16,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
	},
});

let serviceSlider = new Swiper(".service-slider .swiper", {
	modules: [Pagination],
	slidesPerView: 1.25,
	spaceBetween: 16,
	pagination: {
		el: ".service-slider .swiper-pagination",
		type: "progressbar",
	},
	breakpoints: {
		576: {
			slidesPerView: 2.25,
		},
		768: {
			slidesPerView: 3.25,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 24,
		},
		1440: {
			slidesPerView: 5,
			spaceBetween: 24,
		},
	},
});

const deviceSlider = new Swiper(".device-slider .swiper", {
	modules: [Grid, Pagination],
	slidesPerView: 1.25,
	spaceBetween: 16,
	pagination: {
		el: ".device-slider .swiper-pagination",
		type: "progressbar",
	},
	grid: {
		rows: 2,
		fill: "row",
	},
	breakpoints: {
		576: {
			slidesPerView: 1.25,
		},
		768: {
			slidesPerView: 2.5,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
	},
});

const doctorSlider = new LoopSlider(
	".doctor-slider",
	{
		576: {
			spaceBetween: 16,
			slidesPerView: 2.5,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 3.5,
		},
		1024: {
			spaceBetween: 32,
			slidesPerView: 4,
		},
	},
	{
		slidesPerView: 1.25,
		autoplay: {
			delay: 4000,
		},
	}
);

const newsSlider = new LoopSlider(".news-slider", {
	576: {
		spaceBetween: 16,
		slidesPerView: 2.5,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 3.5,
	},
	1024: {
		spaceBetween: 32,
		slidesPerView: 4,
	},
});

const packageSlider = new LoopSlider(".package-slider", {
	576: {
		spaceBetween: 16,
		slidesPerView: 1.5,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 2.5,
	},
	1024: {
		spaceBetween: 32,
		slidesPerView: 3,
	},
});
