import { Fancybox } from "@fancyapps/ui";
import "../_plugins/@master/css";
import "./modules/Header";
import "./modules/Slider";
import "./modules/Accordion";
import "./modules/CountUp";
import "./modules/BackToTop";
import "./modules/Tab";
import "./modules/ExpandContent";
import "./modules/Home";
import "./modules/ServiceDetail";

var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;

Fancybox.bind("[data-fancybox]", {
	// Your custom options
});

$(function () {
	if ($(".footer-has-background").length > 0) {
		$(".global-footer").css(
			"background-color",
			$(".footer-has-background").css("background-color")
		);
	}
});
