function collapseComponent() {
	$(".accordion-item").each(function () {
		let _this = $(this);
		let otherList = $(".accordion-item").not(_this);
		let bodyItem = _this.find(".accordion-body");
		let otherBody = $(otherList).find(".accordion-body");

		_this.on("click", function () {
			$(bodyItem).slideToggle();
			$(otherBody).slideUp();
			_this.toggleClass("active");
			$(otherList).removeClass("active");
		});
	});
	if ($(".accordion-list-no-trigger").length === 0) {
		$(".accordion-item").eq(0).trigger("click");
	}
}

collapseComponent();
